import Styled from "styled-components";
import { Colors } from "../../../contants";

const AboutUs = Styled.section`
  .wrapper {
    background: #FFFFFF;
    padding: 60px 0;
  }
  .content {
    ${(props) =>
      props.width && props.width <= 600
        ? "margin: 0px 32px;"
        : props.width && props.width >= 2000
        ? "margin: 0px 25vw;"
        : props.width && props.width >= 1250
        ? "margin: 0px 12vw;"
        : "margin: 0px 80px;"}
    flex-direction: ${(props) =>
      props.width ? `${props.width <= 960 ? "column" : "row"}` : "row"};
    display: flex;
    align-items: center;
  }

  .container {
    display: flex;
    width: ${(props) =>
      props.width ? `${props.width <= 960 ? "100%" : "50%"}` : "50%"};
    flex-direction: column;

    .service-yoben-image {
      height: 100%;
      border: 8px solid white;
      border-radius: 20px;
      box-shadow: 0px 0px 20px rgba(45, 104, 140, 0.2);

      img {
        height: 100%;
        margin: auto;
        display: block;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .insight-yoben-container {
      display: flex;
      .insight-yoben-subcontainer {
        display: flex;
        flex-direction: column;
        width: 50%;

        .insight-text {
          margin: 0px;
          font-family: Maven Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 38px;
          color: ${Colors.blue.default};
          margin-bottom: 10px;
        }

        .insight-description {
          margin: 0px;
          margin-bottom: 30px;
          font-family: Maven Pro;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }

        .orange {
          color: ${Colors.orange.default};
        }
      }
    }
  }

  .right {
    margin-left: ${(props) =>
      props.width ? `${props.width <= 960 ? "0px" : "30px"}` : "30px"};
    ${(props) =>
      props.width ? `${props.width <= 960 ? "margin-top: 30px" : ""}` : ""};
  }

  .left {
    margin-right: ${(props) =>
      props.width ? `${props.width <= 960 ? "0px" : "30px"}` : "30px"};
  }`;

export { AboutUs };
