import React from "react";
import { Input } from "antd";

import serviceYoben from "../../assets/images/service_yoben.jpg";
import { Colors } from "../../contants";
import { CustomButton, CustomText } from "../../components";
import { Container } from "./style.js";
import { useViewport } from "../../helpers";
import ellipsisBig from "../../assets/images/ellipse_big.png";
import ellipsisSmall from "../../assets/images/ellipse_small.png";
import lineShort from "../../assets/images/line-short.png";
import lineLong from "../../assets/images/line-long.png";
import icPlay from "../../assets/images/ic_play.png";

const ContactUs = () => {
  const { width } = useViewport();
  const scrollInto = (name) => {
      const el = document.getElementById(name);
      let item = el;
      let wrapper = document.getElementById("container");
      let count = item.offsetTop - wrapper.scrollTop - 80; // xx = any extra distance from top ex. 60
      // eslint-disable-next-line no-undef
      window.scrollTo({ top: count, left: 0, behavior: "smooth" });
  };

  return (
    <Container width={width}>
      {width > 960 && <img className="decor-ellipsis" src={ellipsisBig} />}
      {width > 960 && (
        <img className="decor-ellipsis-small" src={ellipsisSmall} />
      )}
      {width > 960 && <img className="decor-line-short" src={lineShort} />}
      {width > 960 && <img className="decor-line-long" src={lineLong} />}

      <section className="contact-us-container">
        <div
          className="max-layout"
          style={{
            display: width <= 960 ? "block" : "flex",
            zIndex: 10,
          }}
        >
          <div className="contact-us-wrapper contact-us-left">
            <CustomText
              color={Colors.white.default}
              size={width <= 960 ? "large-med" : "large"}
              margin="0 0 20px 0"
              weight="700"
              text="Jasa Kebersihan dan Kenyamanan, Bagi kamu dan Rumahmu!"
              lineHeight="55px"
            />
            <div>
              <CustomText
                color={Colors.white.default}
                margin="0 0 20px 0"
                size="medium"
                text="Perkenalkan Yoben, jasa kebersihan dan pijat datang ke rumah yang praktis dan professional!
                Bersihkan rumah dan berikan kenyamanan tubuh anda secara maksimal"
              />
            </div>
            <div style={{
              zIndex: 10
            }}>
              {/* <CustomText
                color={Colors.white.default}
                weight="700"
                text="Hubungi kami melalui Whatsapp 0811-288-5967"
                size="medium"
              /> */}
              <CustomButton
                size={"large"}
                background={Colors.white.default}
                margin={"20px 0px"}
                text={"Pesan Sekarang"}
                color={Colors.blue.default}
                border={Colors.blue.default}
                className="learnmore"
                onClick={() => scrollInto("howItWorks")
                  // window.open("https://wa.me/628112885967", "_blank")
                }
              />
            </div>

            {/* <div>
              <CustomText
                color={Colors.white.default}
                weight="700"
                text="Keep in touch with us!"
              />
              <div className="contact-button-container">
                <Input
                  className="keep-in-touch-input"
                  placeholder="Your email address"
                />
                <ButtonStyled background={Colors.blue.dark}>Send</ButtonStyled>
                <CustomButton
                  size={"large"}
                  text={"Send"}
                  color={Colors.white.default}
                  background={Colors.blue.dark}
                  margin={"20px 0px 20px 0px"}
                  onClick={() => this.props.changePage(tab.key)}
                />
              </div>
            </div> */}
            {/* <p className="knowmore-text">
              <span>
                <img src={icPlay} />
              </span>
              <span>Know more about us.</span>
            </p> */}
          </div>
          {width > 960 && (
            <div className="contact-us-wrapper contact-us-right">
              <div
                style={{
                  marginRight: "20px",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <img src={"hero-1.jpg"} importance="low" />
                </div>
                <div>
                  <img src={"hero-2.jpg"} importance="low" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className="contact-us-image"
                  src={"hero-3.jpg"}
                  importance="low"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </Container>
  );
};

export default ContactUs;
