import React from "react";

import { useViewport } from "../../helpers";
import { FooterContainer } from './style';
import yobenLogo from "../../assets/images/logo_yoben_horizontal.svg"
import icFb from "../../assets/images/ic-facebook.png";
import icIg from "../../assets/images/ic-ig.png";;

const Footer = () => {
  const { width } = useViewport();

  return (
    <FooterContainer width={width}>
      <div className="content" width={width}>
        <div className="sub-content">
          <img className="logo" src={yobenLogo} alt="logo" />
          <p>Layanan Pijat & Kebersihan</p>
        </div>
        <div className="sub-content">
          <p className="title">
            <strong>Kantor</strong>
          </p>
          <p>+62 811-2885-967</p>
          <p>Jalan Masjid Nomor 26 Purwokerto <strong>(pusat)</strong></p>
          <p>Jalan Letkol Isdiman no. 114 b-c, Purbalingga</p>
        </div>
        <div className="sub-content">
          <p className="title">
            <strong>Ikuti Kami</strong>
          </p>
          <div className="socmed">
            <a
              href="https://www.instagram.com/yoben.idn/"
              target="_blank"
            >
              <img className="add-margin-right social-media" src={icIg} />
            </a>
            {/* <img className="add-margin-right social-media" src={icTwitter} />
            <img className="add-margin-right social-media" src={icLinkedIn} /> */}

            <a
              href="https://www.facebook.com/yobenpurwokerto"
              target="_blank"
            >
              <img className=" social-media" src={icFb} />
            </a>
          </div>{" "}
        </div>
      </div>

      <div className="footer-end">
        <p>
          <span className="year-text">© 2021 Yoben</span>
          <span className="right-text">, All Rights Reserved</span>
        </p>
      </div>
    </FooterContainer>
  )
}

export default Footer