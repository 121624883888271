import Styled from 'styled-components';

import { Colors } from '../../contants';

const Text = Styled.p`
  font-family: "Maven Pro", sans-serif;
  color: ${props => (props.color ? props.color : Colors.black.default)};
  font-weight: ${props => props.weight ? props.weight : '400'};
  font-size: ${props => (
    props.size === 'large'
    ? '3em'
    : props.size === 'large-med'
    ? '2em'
    : props.size === 'medium'
    ? '1.25em'
    : props.size === 'small'
    ? '1em'
    : props.size === 'x-small'
    ? '0.875em'
    : props.size
    ? props.size
    : '1em'
  )};
  margin: ${props => props.margin ? props.margin : '0px'};
  line-height: ${props => props.lineHeight ? props.lineHeight : ''};
`;

export { Text };