export default {
  white: {
    default: '#ffffff',
  },
  black: {
    default: '#313537',
  },
  blue: {
    default: '#55bbcc',
    dark: '#2D688C',
    light: 'rgb(85, 187, 204, 0.1)'
  },
  orange: {
    default: '#F8AA00',
  },
  grey: {
    default: '#646C71',
    light: '#FAFAFA'
  }
};
