import Styled from 'styled-components';

const ButtonStyle = Styled.div`
  
  ${props => `
    .ant-btn-lg, .ant-btn, .ant-btn:hover, .ant-btn:focus {
      background-color:${props.background}!important;
      cursor: pointer;
      color: ${props.color};
      font-weight: 700;
      font-family: "Maven Pro", sans-serif;
      width: ${props.width};
      ${props.border ? `border: solid 1px ${props.border}` : 'border: none'};
      ${props.fontSize && `font-size: ${props.fontSize}`};
      border-radius: 8px;
      ${
        props.margin
          ? `margin: ${props.margin}`
          : 'margin: 0'
      };

      ${props.isBadge && 'height: 30px'};
      transition: all 0.3s ease;

      &:hover {
        opacity: unset;
        box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
        transform: translateY(-4px);
      }
      :focus {
        outline: none
      }
    }
  `}
    
`;

export default ButtonStyle;
