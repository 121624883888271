import React, { Component } from 'react';
import { Button } from 'antd';

import ButtonStyle from './style';

class CustomButton extends Component {
  render() {
    return (
      <ButtonStyle
        margin={ this.props.margin }
        tabBar={ this.props.tabBar }
        width={ this.props.width }
        fontSize={ this.props.fontSize }
        border={ this.props.border }
        background={ this.props.background }
        color={ this.props.color }
        isBadge={ this.props.isBadge }
      >
        <Button
          disabled={ this.props.disabled }
          size={ this.props.size }
          loading={ this.props.loading }
          onClick={ this.props.onClick }
          href={ this.props.href ? this.props.href : undefined }
          target='_blank'
          htmlType={ this.props.htmlType ? this.props.htmlType : undefined }
          style={ {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '0px 30px',
            height: '60px'
          } }
        >
          {this.props.icon && this.props.icon}
          {this.props.text}
        </Button>
      </ButtonStyle>
    );
  }
}

export default CustomButton;
