/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import { Dropdown, Input, Avatar, Collapse, Button, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinusCircle,
  faChevronRight,
  faChevronLeft,
  faUser,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import HamburgerMenu from "react-hamburger-menu";
import $ from "jquery";
import gsap from "gsap";
import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { Router, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import yobenLogo from "./assets/images/logo_yoben_horizontal.svg";
import instagramIcon from "./assets/images/instagram_icon.svg";
import icApple from "./assets/images/ic_apple.png";
import icPlaystore from "./assets/images/ic_playstore.png";
import ellipseBottom from "./assets/images/ellipse-bottom.png";
import ellipseTop from "./assets/images/ellipse-top.png";
import decor from "./assets/images/decorator_about_us.png";
import icQuotes from "./assets/images/ic_quotes.png";
import icArrow from "./assets/images/carousel-arrow.png";
import map from "./assets/images/map.png";
import icPinpoint from "./assets/images/pinpoint.png";
import faqBanner from "./assets/images/faq-banner.svg";
import mapPin from "./assets/images/map-pin.png";
import {
  Navigation,
  Container,
  OurService,
  FilterServiceButton,
  ServiceCard,
  ServiceList,
  HowItWorks,
  stepInfo,
  Testimonial,
  NavigationMobile,
  Menu,
  WhatAreYouWaiting,
  DownloadApp,
  CardTestimony,
  AvailableAt,
  LocListItem,
  FAQ,
  QuestionsContainer,
  HowItWorksTitle,
  TermsAndConditions
} from "./style";
import { Colors } from "./contants";
import { ContactUs, AboutUsComponent } from "./screens";
import { CustomText, CustomButton, Footer } from "./components";
import { useViewport, History as history } from "./helpers";
import {
  serviceData,
  locationData,
  faqGeneral,
  faqBugar,
  faqResik,
  testimonies,
  steps,
} from "./data";

const { Panel } = Collapse;

let serviceFilter = [
  {
    id: 0,
    title: "All services",
  },
  {
    id: 1,
    title: "Resik",
  },
  {
    id: 2,
    title: "Bugar",
  },
  // {
  //   id: 3,
  //   title: "Promo",
  // },
];

let faqFilter = [
  {
    id: 0,
    title: "Pertanyaan Umum",
  },
  {
    id: 1,
    title: "BUGAR",
  },
  {
    id: 2,
    title: "RESIK",
  },
];

const arrowStyles = {
  zIndex: 2,
  width: 44,
  height: 44,
  cursor: "pointer",
  background: Colors.blue.light,
  padding: "10px",
  border: "none",
  outline: "none",
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const App = () => {
  const { t, i18n } = useTranslation();
  const { width } = useViewport();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedStep, setStep] = useState(0);
  const [selectedStepImg, setStepImg] = useState(steps[0].img);
  const [selectedService, setServiceFilter] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [onAboutUs, setOnAboutUs] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [onOurService, setOnOurService] = useState(false);
  const [isOurServiceVisible, setOurServiceVisible] = useState(false);
  const [onHowItWorks, setOnHowItWorks] = useState(false);
  const [isHowItWorksVisible, setHowItWorksVisible] = useState(false);
  const [onTestimonial, setOnTestimonial] = useState(false);
  const [isTestimonialVisible, setTestimonialVisible] = useState(false);
  const [onAvailableAt, setOnAvailableAt] = useState(false);
  const [isAvailableAtVisible, setAvailableAtVisible] = useState(false);
  const [onFAQ, setOnFAQ] = useState(false);
  const [onTnC, setOnTnC] = useState(false);
  const [carouselHowItWorks, setCarouselHowItWorks] = useState(0);

  useEffect(() => {
    if (openMenu) {
      // showMenu.play();
      gsap.to("#menu-link", { x: "100vw", duration: 0.5 });
      gsap.to("#menu-overlay", { display: "unset", opacity: 1, duration: 0.5 });
    } else {
      // showMenu.reverse();
      gsap.to("#menu-link", { x: "-100vw", duration: 0.5 });
      gsap.to("#menu-overlay", { display: "none", opacity: 0, duration: 0.5 });
    }
  }, [openMenu]);

  // useEffect(() => {
  //   if (isAboutVisible) {
  //     // setPosition('intro');
  //     gsap.fromTo('#about-left', { x: '-80vw' }, { x: '0vw', opacity: 1, duration: 2, delay: 0.25 });
  //     gsap.fromTo('#about-right', { x: '60vw' }, { x: '0vw', opacity: 1, duration: 2, delay: 0.25 });
  //   }
  // }, [isAboutVisible]);

  // useEffect(() => {
  //   isScrolledIntoView($('#aboutUs')) && setIsAboutVisible(true);
  //   isScrolledIntoView($('#ourService')) && setOurServiceVisible(true);
  //   isScrolledIntoView($('#howItWorks')) && setHowItWorksVisible(true);
  //   isScrolledIntoView($('#testimonial')) && setTestimonialVisible(true);
  //   isScrolledIntoView($('#availableAt')) && setAvailableAtVisible(true);
  // }, []);

  const onChangePage = (page, scrollTo) => {
    setOpenMenu(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line no-undef
    window.removeEventListener("scroll", onScrollContainer);
    if (page === "/faq") {
      setOnAboutUs(false);
      setOnOurService(false);
      setOnHowItWorks(false);
      setOnTestimonial(false);
      setOnAvailableAt(false);
      setOnFAQ(true);
      setOnTnC(false);
    } else if (page === "/terms-and-conditions") {
      setOnAboutUs(false);
      setOnOurService(false);
      setOnHowItWorks(false);
      setOnTestimonial(false);
      setOnAvailableAt(false);
      setOnFAQ(false);
      setOnTnC(true);
    } else {
      setOnFAQ(false);
      setOnTnC(false);
    }
    history.push(page);
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      if (scrollTo) {
        scrollInto(scrollTo);
      }
    }, 500);
  };

  const scrollInto = (name) => {
    if (history.location.pathname == "/") {
      // eslint-disable-next-line no-undef
      const el = document.getElementById(name);
      // el.scrollIntoView();
      let item = el;
      // eslint-disable-next-line no-undef
      let wrapper = document.getElementById("container");
      let count = item.offsetTop - wrapper.scrollTop - 80; // xx = any extra distance from top ex. 60
      // eslint-disable-next-line no-undef
      window.scrollTo({ top: count, left: 0, behavior: "smooth" });
    }
  };

  const onClickStep = (chosenStep) => {
    if (selectedStep !== chosenStep.id) {
      setStep(chosenStep.id);
    } else if (width <= 960 && selectedStep === chosenStep.id) {
      setStep(0);
    } else {
      setStep(1);
    }
    setCarouselHowItWorks(chosenStep.id);
  };

  const onScrollContainer = (e) => {
    let scrollTop = e.target.scrollTop;
    setScrollX(scrollTop);
    isScrolledIntoView($("#aboutUs"), false, "#aboutUs") &&
      setIsAboutVisible(true);
    isScrolledIntoView($("#ourService"), false, "#ourService") &&
      setOurServiceVisible(true);
    isScrolledIntoView($("#howItWorks"), false, "#howItWorks") &&
      setHowItWorksVisible(true);
    isScrolledIntoView($("#testimonial"), false, "#testimonial") &&
      setTestimonialVisible(true);
    isScrolledIntoView($("#availableAt"), false, "#availableAt") &&
      setAvailableAtVisible(true);

    // if (width <= 960) {
    // isScrolledIntoView($('#services-img'), true) && setIsServicesImgVisible(true);
    // isScrolledIntoView($('#services-checklist'), false, '#services-checklist') && setIsServicesChecklistVisible(true);
    // } else {
    // isScrolledIntoView($('#services'), false) && setIsServicesVisible(true);
    // }
  };
  const isScrolledIntoView = (element, fullyInView, name) => {
    if (history.location.pathname !== "/faq") {
      // eslint-disable-next-line no-undef
      var pageTop = $(window).scrollTop();
      // eslint-disable-next-line no-undef
      var pageBottom = pageTop + $(window).height();
      var elementTop = $(element).offset().top;
      var elementBottom = elementTop + $(element).height();
      let flag;
      if (fullyInView) {
        flag = pageTop < elementTop && pageBottom > elementBottom;
      } else {
        flag = elementTop <= pageBottom && elementBottom >= pageTop;
      }
      if (name) {
        switch (name) {
          case "#aboutUs":
            if (flag) {
              setOnAboutUs(true);
              setOnOurService(false);
              setOnHowItWorks(false);
              setOnTestimonial(false);
              setOnAvailableAt(false);
            } else {
              setOnAboutUs(false);
            }
            break;
          case "#ourService":
            if (flag) {
              setOnAboutUs(false);
              setOnOurService(true);
              setOnHowItWorks(false);
              setOnTestimonial(false);
              setOnAvailableAt(false);
            } else {
              setOnOurService(false);
            }
            break;
          case "#howItWorks":
            if (flag) {
              setOnAboutUs(false);
              setOnOurService(false);
              setOnHowItWorks(true);
              setOnTestimonial(false);
              setOnAvailableAt(false);
            } else {
              setOnHowItWorks(false);
            }
            break;
          case "#testimonial":
            if (flag) {
              setOnAboutUs(false);
              setOnOurService(false);
              setOnHowItWorks(false);
              setOnTestimonial(true);
              setOnAvailableAt(false);
            } else {
              setOnTestimonial(false);
            }
            break;
          case "#availableAt":
            if (flag) {
              setOnAboutUs(false);
              setOnOurService(false);
              setOnHowItWorks(false);
              setOnTestimonial(false);
              setOnAvailableAt(true);
            } else {
              setOnAvailableAt(false);
            }
            break;
          default:
            break;
        }
      }

      return flag;
    }
  };

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  const renderNavigation = () => {
    return (
      <Navigation scrollX={scrollX} width={width}>
        <div className="nav-container">
          <div className="nav-icon" onClick={() => {
            scrollInto("container");
            onChangePage("/");
          }}>
            <img src={yobenLogo} alt="logo" />
          </div>
          <div className="text-primary">
            <p
              className={`${onAboutUs ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "aboutUs")
                  : scrollInto("aboutUs")
              }
            >
              Tentang Kami
            </p>
            <p
              className={`${onOurService ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "ourService")
                  : scrollInto("ourService")
              }
            >
              Layanan Kami
            </p>
            <p
              className={`${onHowItWorks ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "howItWorks")
                  : scrollInto("howItWorks")
              }
            >
              Cara Kerja
            </p>
            <p
              className={`${onAvailableAt ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "availableAt")
                  : scrollInto("availableAt")
              }
            >
              Lokasi
            </p>
            <p
              className={`${onTestimonial ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "testimonial")
                  : scrollInto("testimonial")
              }
            >
              Testimonial
            </p>
            <p
              className={`${onFAQ ? "active" : ""}`}
              onClick={() => onChangePage("/faq")}
            >
              FAQ
            </p>
            <p
              className={`${onTnC ? "active" : ""}`}
              onClick={() => onChangePage("/terms-and-conditions")}
            >
              Syarat dan Ketentuan
            </p>
          </div>

          <a href="https://www.instagram.com/yoben.idn/" target="_blank">
            <div className="nav-instagram">
              <img src={instagramIcon} alt="instagram-icon" />
              <p className="follow">Follow Our Instagram</p>
            </div>
          </a>
        </div>
      </Navigation>
    );
  };

  const renderNavigationMobile = () => {
    return (
      <NavigationMobile scrollX={scrollX}>
        <div className="nav-icon">
          <img src={yobenLogo} alt="logo" />
        </div>
      </NavigationMobile>
    );
  };

  const renderMenuNavMobile = () => {
    return (
      <Menu id="menu">
        <div
          id="menu-overlay"
          className="overlay"
          onClick={() => setOpenMenu(false)}
        />
        <div id="menu-link" className="link">
          <div className="top">
            <p
              className={`${onAboutUs ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "aboutUs")
                  : scrollInto("aboutUs")
              }
            >
              Tentang Kami
            </p>
            <p
              className={`${onOurService ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "ourService")
                  : scrollInto("ourService")
              }
            >
              Layanan Kami
            </p>
            <p
              className={`${onHowItWorks ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "howItWorks")
                  : scrollInto("howItWorks")
              }
            >
              Cara Kerja
            </p>
            <p
              className={`${onAvailableAt ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "availableAt")
                  : scrollInto("availableAt")
              }
            >
              Lokasi
            </p>
            <p
              className={`${onTestimonial ? "active" : ""}`}
              onClick={() =>
                history.location.pathname !== "/"
                  ? onChangePage("/", "testimonial")
                  : scrollInto("testimonial")
              }
            >
              Testimoni
            </p>
            <p
              className={`${onFAQ ? "active" : ""}`}
              onClick={() => onChangePage("/faq")}
            >
              FAQ
            </p>
            <p
              className={`${onTnC ? "active" : ""}`}
              onClick={() => onChangePage("/terms-and-conditions")}
            >
              Syarat dan Ketentuan
            </p>
          </div>
          <div className="bottom">
            <a
              href="https://www.instagram.com/yoben.idn/"
              target="_blank"
            >
              <div className="nav-instagram">
                <img src={instagramIcon} alt="instagram-icon" />
                <p className="follow">Follow Our Instagram</p>
              </div>
            </a>
          </div>
        </div>
      </Menu>
    );
  };

  const renderServiceCard = (service) => {
    return (
      <ServiceCard key={service.id} width={width} className="service-card">
        <img src={service.img} />
        <CustomText
          color={Colors.black.default}
          size="medium"
          margin="20px 0 10px 0"
          weight="700"
          text={service.title}
        />
        <CustomText
          color={Colors.grey.default}
          size="small"
          margin="0"
          weight="500"
          text={service.desc}
        />
      </ServiceCard>
    );
  };

  const renderServiceList = () => {
    switch (selectedService) {
      case 0:
        return serviceData;
        break;
      case 1:
        return serviceData.filter((service) =>
          service.service == "resik" ? true : false
        );
        break;
      case 2:
        return serviceData.filter((service) =>
          service.service == "bugar" ? true : false
        );
      case 3:
        return serviceData.filter((service) =>
          service.isPromo == true ? true : false
        );
      default:
        break;
    }
  };

  const renderOurService = () => {
    return (
      <OurService id="ourService" width={width}>
        <div className="wrapper">
          <div className="content">
            <div className="content-description">
              <div className="content-left">
                <CustomText
                  color={Colors.grey.default}
                  size="medium"
                  margin="0 0 10px 0"
                  weight="700"
                  text="LAYANAN KAMI"
                />
                {/* <CustomText
                  color={Colors.black.default}
                  size={width <= 960 ? "large-med" : "large"}
                  margin="0 0 10px 0"
                  weight="700"
                  text="Layanan Kami"
                /> */}
                <h1>
                  <span className="resik-text">RESIK</span> dan{" "}
                  <span className="bugar-text">BUGAR</span>.
                </h1>
              </div>
              <div className="content-right">
                {serviceFilter.map((filter) => (
                  <FilterServiceButton
                    key={filter.id}
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      // setTimeout(() => {
                      //   return setServiceFilter(filter.id);
                      // }, 500);

                      return setServiceFilter(filter.id);
                    }}
                    selected={selectedService === filter.id}
                  >
                    <CustomText
                      size="medium"
                      margin="0"
                      weight="700"
                      text={filter.title}
                    />
                  </FilterServiceButton>
                ))}
              </div>
            </div>
            <ServiceList>
              {renderServiceList().map((service) => renderServiceCard(service))}
            </ServiceList>
          </div>
        </div>
      </OurService>
    );
  };

  const renderDownloadApp = () => {
    return (
      <DownloadApp width={width}>
        <Row gutter={30} direction="col">
          <Col span={12}>
            <CustomButton
              size={"small"}
              background={Colors.white.default}
              text={<img src={icApple} />}
              onClick={() => window.open("https://apps.apple.com/id/app/yoben/id1549361780", "_blank")}
            />
          </Col>
          <Col span={12}>
            <CustomButton
              size={"small"}
              background={Colors.white.default}
              text={<img src={icPlaystore} />}
              onClick={() => window.open("https://play.google.com/store/apps/details?id=com.yobencustomer", "_blank")}
            />
          </Col>
        </Row>
      </DownloadApp>
    );
  };

  const renderStepsMobile = (step) => {
    let isSelected = selectedStep === step.id;
    return (
      <div
        key={step.id}
        style={{
          display: "flex",
        }}
      >
        <div className="step-wrapper">
          <div className="step-title" onClick={() => onClickStep(step)}>
            <div className="title">
              <div className="step-number">
                <p>{step.id + 1}</p>
              </div>
              <HowItWorksTitle width={ width } isSelected={ isSelected }>{step.title}</HowItWorksTitle>
            </div>
            <FontAwesomeIcon
              style={{ color: Colors.blue.default }}
              icon={isSelected ? faChevronUp : faChevronDown}
              size="2x"
            />
          </div>
          {isSelected && (
            <div className="step-detail">
              {width <= 960 && step.id !== 0 && <img className="app-img" src={step.img} />}
              {step.id === 0 ? (
                renderDownloadApp()
              ) : (
                <CustomText
                  color={Colors.grey.default}
                  size="small"
                  margin="10px 0 10px 0"
                  weight="700"
                  text={step.desc}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderHowItWorks = () => {
    return (
      <HowItWorks id="howItWorks" width={width}>
        <div className="wrapper">
          <div className="content">
            <div className="content-left" style={{
              display: width <= 960 ? "none" : ""
            }}>
              <img src={decor} className="decor-top" />
              <Carousel
                value={carouselHowItWorks}
                numberOfSlides={1}
                slides={steps.map((step, index) => {
                  return <div style={{
                    height: "500px",
                  }}>
                    <img style = {{
                      objectFit: "scale-down",
                      height: "100%",
                    }} className="step-image" src={step.img}></img>
                  </div>;
                })}
                draggable={false}
              ></Carousel>
            </div>
            <div className="content-right">
              <CustomText
                color={Colors.grey.default}
                size="medium"
                margin="0 0 10px 0"
                weight="700"
                text="CARA KERJA"
              />
              <CustomText
                color={Colors.black.default}
                size={width <= 960 ? "large-med" : "large"}
                margin="0 0 10px 0"
                weight="700"
                text="Langkah Mudah
                Memesan Jasa Kami!"
              />
              {/* <CustomText
                color={Colors.grey.default}
                size="small"
                margin="0 0 30px 0"
                weight="700"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc,
                tellus in cursus cras elementum."
              /> */}
              {steps.map((step) => renderStepsMobile(step))}
              {/* <CustomButton
                size={"large"}
                background={Colors.white.default}
                margin={"20px 20px 20px 0px"}
                text={"Learn more"}
                color={Colors.blue.default}
                border={Colors.blue.default}
                className="learnmore"
              /> */}
            </div>
          </div>
        </div>
      </HowItWorks>
    );
  };

  const renderCardTesti = (testi, isMargin) => {
    return (
      <CardTestimony
        width={width}
        style={{ marginRight: isMargin ? "20px" : "0px" }}
      >
        <img src={icQuotes} className="ic-quote" />
        <CustomText
          color={Colors.grey.default}
          size={"small"}
          margin="40px 0 40px 0"
          weight="700"
          text={testi.desc}
        />
        <div className="user-profile">
          <Avatar style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center"
          }}>
            <FontAwesomeIcon
              icon={faUser}
              size={"2x"}
            />
          </Avatar>
          <div>
            <CustomText
              color={Colors.blue.default}
              size={"small"}
              weight="700"
              text={testi.name}
            />
            <CustomText
              color={Colors.grey.default}
              size={"x-small"}
              weight="500"
              text={`${testi.place}, ${testi.age}`}
            />
          </div>
        </div>
      </CardTestimony>
    );
  };

  const renderTestimonial = () => {
    return (
      <Testimonial id="testimonial" width={width}>
        <div className="wrapper">
          <div className="content">
            <div className="content-title">
              <CustomText
                color={Colors.grey.default}
                size="medium"
                margin="0 0 10px 0"
                weight="700"
                text="TESTIMONI"
              />
              <CustomText
                color={Colors.black.default}
                size={width <= 960 ? "large-med" : "large"}
                margin="0 0 10px 0"
                weight="700"
                text="Apa yang mereka bilang tentang YOBEN?"
              />
            </div>
            <div className="carousel-container">
              <Carousel
                numberOfSlides={width <= 780 ? 1 : 2}
                dots
                infinite
                slidesPerPage={width <= 780 ? 1 : 2}
                clickToChange
                slides={testimonies.map((testi, i) => {
                  return renderCardTesti(testi, false);
                })}
                breakpoints={{
                  450: {
                    plugins: [
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides: 1,
                        },
                      },
                    ],
                  },
                  900: {
                    plugins: [
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides: 2,
                        },
                      },
                    ],
                  },
                }}
                arrowRight={
                  width <= 960 ? (
                    <FontAwesomeIcon
                      style={{ color: Colors.blue.default }}
                      icon={faChevronRight}
                      size={"2x"}
                    />
                  ) : (
                    <button
                      className="arrow arrow-left"
                      src={icArrow}
                      style={{ ...arrowStyles }}
                    >
                      <img
                        src={icArrow}
                        style={{ transform: "rotate(180deg)", zIndex: 100 }}
                        alt=""
                      />
                    </button>
                  )
                }
                arrowLeft={
                  width <= 960 ? (
                    <FontAwesomeIcon
                      style={{ color: Colors.blue.default }}
                      icon={faChevronLeft}
                      size={"2x"}
                    />
                  ) : (
                    <button
                      className="arrow arrow-left"
                      src={icArrow}
                      style={{ ...arrowStyles }}
                    >
                      <img src={icArrow} alt="" />
                    </button>
                  )
                }
                addArrowClickHandler
              />
            </div>
          </div>
        </div>
      </Testimonial>
    );
  };

  const renderWhatAreYouWaiting = () => {
    return (
      <WhatAreYouWaiting width={width}>
        <img src={ellipseBottom} className="ellipse-bottom" />
        <img src={ellipseTop} className="ellipse-top" />
        <CustomText
          color={Colors.white.default}
          size={width <= 960 ? "large-med" : "large"}
          margin="0 0 10px 0"
          weight="700"
          text="Tunggu apa lagi? Pesan Sekarang!"
        />
        
        <DownloadApp width={width}>
          {renderDownloadApp()}
          {/* <CustomButton
            size={"small"}
            background={Colors.white.default}
            margin={"20px 0px 20px 0px"}
            text={"Hubungi kami"}
            icon={
              <img
                style={{
                  marginRight: "15px",
                }}
                src="ic_whatsapp.svg"
              />
            }
            onClick={() => window.open("https://wa.me/628112885967", "_blank")}
          /> */}
        </DownloadApp>
      </WhatAreYouWaiting>
    );
  };

  const renderLocationListItem = (loc) => {
    return (
      <LocListItem>
        <img src={icPinpoint} />
        <p>
          <span>{loc.name}</span>
          {loc.desc && <span className="loc-desc">{` (${loc.desc})`}</span>}
        </p>
      </LocListItem>
    );
  };

  const renderAvailableAt = () => {
    return (
      <AvailableAt width={width} id="availableAt">
        <div className="detail-container">
          <CustomText
            color={Colors.grey.default}
            size="medium"
            margin="0 0 10px 0"
            weight="700"
            text="LOKASI"
          />
          <CustomText
            color={Colors.black.default}
            size={width <= 960 ? "large-med" : "large"}
            margin="0 0 10px 0"
            weight="700"
            text="Kami tersedia di beberapa wilayah"
          />
          {locationData.map((loc) => renderLocationListItem(loc))}
          {/* <CustomText
            color={Colors.blue.default}
            size="small"
            margin="20px 0 0px 0"
            weight="700"
            text="See 20 more available places"
          /> */}
        </div>
        <div className="map-container">
          {/* <img src={ mapPin }/> */}
          <img src={map} />
        </div>
      </AvailableAt>
    );
  };

  const renderNav = () => {
    return (
      <>
        {width <= 960 && (
          <div className="burger">
            <HamburgerMenu
              isOpen={openMenu}
              menuClicked={() => setOpenMenu(!openMenu)}
              width={20}
              height={14}
              strokeWidth={1}
              rotate={0}
              color="#102028"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        )}
        {width <= 960 ? renderNavigationMobile() : renderNavigation()}
        {width <= 960 && renderMenuNavMobile()}
      </>
    );
  };

  const renderMainContent = () => {
    return (
      <Container className="global" id="container" width={width}>
        <link
          href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        {renderNav()}
        <div className="main-content">
          <ContactUs />
          <AboutUsComponent />
          {renderOurService()}
          {renderHowItWorks()}
          {renderAvailableAt()}
          {renderWhatAreYouWaiting()}
          {renderTestimonial()}
          <Footer/>
        </div>
      </Container>
    );
  };

  const renderQuestions = () => {
    let currentFaq = [];
    if (selectedService === 0) {
      currentFaq = faqGeneral;
    } else if (selectedService === 1) {
      currentFaq = faqBugar;
    } else if (selectedService === 2) {
      currentFaq = faqResik;
    }

    return (
      <QuestionsContainer width={width}>
        <div className="filter-container">
          {faqFilter.map((filter) => (
            <FilterServiceButton
              key={filter.id}
              onClick={() => setServiceFilter(filter.id)}
              selected={selectedService === filter.id}
            >
              <CustomText
                color={Colors.white.default}
                size="medium"
                margin="0"
                weight="700"
                text={filter.title}
              />
            </FilterServiceButton>
          ))}
        </div>
        <Collapse
          defaultActiveKey={["0"]}
          // onChange={ callback }
          expandIconPosition={"right"}
          className="site-collapse-custom-panel"
          bordered={false}
          expandIcon={({ isActive }) => (
            <FontAwesomeIcon
              style={{ color: Colors.blue.default }}
              icon={isActive ? faMinusCircle : faPlus}
              size={20}
            />
          )}
        >
          {currentFaq.map((faq) => (
            <Panel header={faq.title} key={faq.id}>
              <CustomText
                color={Colors.grey.default}
                size="small"
                margin="0"
                weight="500"
                text={faq.desc}
              />
            </Panel>
          ))}
        </Collapse>
      </QuestionsContainer>
    );
  };

  const renderFaq = () => {
    return (
      <Container width={width}>
        {renderNav()}
        <FAQ width={width}>
          <div className="content">
            <div className="faq-banner">
              <img src={faqBanner} />
              <div className="faq-title">
                <CustomText
                  color={Colors.white.default}
                  size={width <= 960 ? "large-med" : "large"}
                  margin="0 0 10px 0"
                  weight="700"
                  text="Halo, ada yang bisa kami bantu? "
                />
                {/* <div className="contact-button-container">
                  <Input
                    className="keep-in-touch-input"
                    placeholder="Ask question"
                  />

                  <CustomButton
                    size={"large"}
                    text={"Search"}
                    color={Colors.white.default}
                    background={Colors.blue.dark}
                    margin={"20px 0px 20px 0px"}
                  />
                </div> */}
              </div>
            </div>
          </div>
          {renderQuestions()}
        </FAQ>
        <Footer/>
      </Container>
    );
  };

  const renderTnC = () => {
    return (
      <Container width={width}>
        {renderNav()}
        <TermsAndConditions width={width}>
          <div className="content">
            <div className="tnc-title">
              <h1>Syarat dan Ketentuan</h1>
              <p><strong>mulai dari 10 Februari 2021</strong></p>
            </div>
            <h2>Ketentuan Umum</h2>
            <p>Dalam Syarat dan Ketentuan ini yang dimaksud dengan:</p>
            <ol type="1">
              <li><strong>YOBEN</strong> adalah layanan jasa kebersihan dan kebugaran yang didirikan berdasarkan hukum negara Republik Indonesia yang saat ini terdaftar sebagai Usaha Mikro.</li>
              <li><strong>Aplikasi</strong> adalah aplikasi perangkat lunak YOBEN yang telah kami kembangkan yang berfungsi sebagai sarana untuk menemukan pelayanan yang disediakan oleh pihak ketiga.</li>
              <li><strong>Pelanggan</strong> adalah Anda yang memesan pelayanan.</li>
              <li><strong>Pelayanan</strong> adalah layanan yang dipilih oleh pelanggan sesuai dengan order yang diminta melalui aplikasi.</li>
              <li><strong>Mitra YOBEN</strong> adalah pihak ketiga yang memiliki kontrak dengan kami guna untuk membantu kami dalam memberikan layanan.</li>
              <li><strong>Informasi Pribadi</strong> adalah identifikasi informasi pribadi seseorang yang dapat diisi melalui aplikasi YOBEN seperti halnya nama, alamat, tanggal lahir, jenis kelamin, nomor telepon,l dan/atau sejenisnya, dan informasi lainnya yang dapat digunakan untuk mengidentifikasi seseorang yang menggunakan aplikasi YOBEN.</li>
              <li><strong>Situs web</strong> adalah situs web kami yaitu www.yoben.co.id.</li>
            </ol>
            <h2>Kontrak Kami Dengan Pelanggan</h2>
            <ol>
              <li>Pelanggan wajib memberikan informasi secara lengkap dan benar.</li>
              <li>Apabila terdapat keluhan pelanggan mengenai layanan hanya dapat diterima dan diproses maksimal 2 hari setelah tanggal pelayanan. Selebihnya pihak YOBEN berhak untuk tidak melayani keluhan atas pelayanannya.</li>
              <li>Segala risiko yang timbul karena keadaan memaksa (force majeure) seperti: bencana alam, kebakaran, huru-hara dan lain sebagainya tidak menjadi tanggung jawab kami.</li>
            </ol>
            <h2>Perubahan Layanan</h2>
            <ol>
              <li>Apabila layanan telah dilakukan, pelanggan dapat melakukan perubahan layanan dengan layanan yang sama dengan durasi yang lebih besar dari layanan yang sudah dipesan sebelumnya dan juga dari pihak mitra, bersedia untuk menambah jam layanan.</li>
              <li>Pelanggan yang melakukan perubahan jadwal layanan harus membatalkan order sebelumnya dan membuat order baru.</li>
              <li>Apabila pelanggan ingin menambah layanan dengan mitra yang sama, maka pelanggan harus melakukan order baru kembali dengan konfirmasi ke admin.</li>
            </ol>
            <h2>Pembatalan Pesanan</h2>
            <ol>
              <li>Pelanggan tidak dapat membatalkan layanan ketika layanan sudah berjalan.</li>
              <li>Pembatalan hanya dapat dilakukan 30 menit sebelum waktu yang telah di pesan.</li>
              <li>Apabila anda membatalkan pesanan ketika mitra kami sudah menuju ke lokasi, maka anda akan dikenakan biaya pembatalan sebesar 50% dari total nilai order.</li>
            </ol>
            <h2>Harga dan Pembayaran</h2>
            <ol>
              <li>Harga pemesanan terdapat pada layanan YOBEN dapat berubah dari waktu ke waktu.</li>
              <li>Anda memahami bahwa penggunaan layanan mengharuskan anda untuk melakukan pembayaran terhadap pelayanan yang anda terima. Setelah anda menerima layanan penuh yang diperoleh melalui penggunaan layanan, maka mitra kami akan memproses pembayaran biaya tersebut.</li>
              <li>Seluruh pembayaran pada YOBEN menggunakan uang tunai, electronic wallet dan media transfer bank.</li>
              <li>Biaya akan termasuk pajak yang mana berdasarkan oleh hukum. Biaya yang dibayarkan oleh anda bersifat final dan tidak dapat dikembalikan.</li>
              <li>YOBEN berhak untuk membuat, menghapus dan/atau merevisi biaya untuk semua aspek layanan setiap saat dalam kebijakan YOBEN.</li>
              <li>YOBEN dari waktu ke waktu dapat memberikan pengguna tertentu dengan menawarkan promosi dan diskon yang dapat berdampak pada biaya yang berbeda untuk layanan yang sama atau sejenisnya.</li>
            </ol>
            <h2>Keadaan Memaksa (Force Majeure)</h2>
            <ol>
              <li>Kami tidak akan bertanggung jawab untuk setiap kegagalan untuk melakukan pelayanan atau keterlambatan dalam kinerja yang mana disebabkan oleh Keadaan Memaksa (Force Majeure).</li>
              <li>Keadaan Memaksa adalah kejadian atau keadaan yang terjadi diluar kuasa dari pihak YOBEN serta para pihak yang bersangkutan.</li>
              <li>Keadaan Memaksa seperti: bencana alam, kebakaran, huru-hara, perang, kerusuhan, serangan teroris, ancaman serangan teroris, dan hal lain yang sejenis.</li>
            </ol>
            <h2>Informasi Tentang Kami dan Bagaimana Menghubungi Kami</h2>
            <ol>
              <li>Kantor kami berada di Jl. Masjid No.26, Purwokerto 53115,Kab.Banyumas, Jawa Tengah.</li>
              <li>Jika anda memiliki pertanyaan atau jika anda memiliki keluhan, silahkan menghubungi kami melalui Whatsapp di +62 811-2885-967.</li>
            </ol>
            <h2>Bagaimana Kami Menggunakan Informasi Pribadi Anda</h2>
            <p>Jika anda adalah pelanggan, kami akan menggunakan informasi pribadi yang anda berikan kepada kami untuk:</p>
            <ol>
              <li>Menyediakan Layanan.</li>
              <li>Memproses pembayaran anda untuk pelayanan.</li>
              <li>Memberitahu tentang produk atau layanan, promosi yang kami sediakan.</li>
            </ol>
            <p>Penambahan, jika anda adalah mitra YOBEN (penyedia layanan):</p>
            <ol>
              <li>Mitra diminta atau diharuskan untuk mengaktifkan Global Positioning System (GPS) pada perangkat seluler untuk memungkinkan kami agar dapat memberikan pengalaman yang lebih baik dalam menggunakan Aplikasi (misalnya, untuk memberikan informasi tentang seberapa dekat penyedia layanan).
                Mitra dapat menonaktifkan informasi pelacakan lokasi geografis pada perangkat seluler ketika tidak ingin mencari layanan.</li>
            </ol>
            <p>Segala syarat-syarat dan ketentuan ini telah dibaca dan disetujui oleh pelanggan. Kami harapkan agar pelanggan memahami melaksanakan ketentuan dan syarat-syarat ini agar terjadi kerjasama yang baik antara pelanggan dan YOBEN.</p>
          </div>
        </TermsAndConditions>
        
        <Footer/>
      </Container>
    )
  }

  const renderPrivacyPolicy = () => {
    return (
      <Container width={width}>
        {renderNav()}
        <TermsAndConditions width={width}>
          <div className="content">
            <div className="tnc-title">
              <h1>Privacy Policy</h1>
              <p><strong>as of 9 November 2021</strong></p>
            </div>
            <p>The following Privacy Policy describes how we, (YOBEN, “we, “us” or “our”) collect, store, use, process, retain, transfer, disclose and protect your Personal Information. This Privacy Policy applies to all users of our applications, unless covered by a separate privacy policy.
Please read this Privacy Policy thoroughly to ensure that you understand our data processing practices. Unless otherwise defined, all capitalized terms used in this Privacy Policy shall have the same meaning ascribed to them in the Terms of Use.</p>
            <p>This Privacy Policy includes the following matters:</p>
            <ol>
              <li>Personal information which we collect</li>
              <li>The use of Personal Information which we collect</li>
              <li>Security of your personal information</li>
              <li>Changes to this privacy policy</li>
              <li>Acknowledgment and consent</li>
              <li>Marketing and promotional material</li>
              <li>How to contact us</li>
            </ol>

            <h3>1. PERSONAL INFORMATION WHICH WE COLLECT</h3>
            <p>Information obtained from you or from your mobile device directly</p>
            <ul>
              <li>When you register and create an account with us using the Application you have to provide to us certain Personal Information, including your name, physical address, and your phone number. If you are using the Application as a service provider, you have to provide us with additional Personal Information as part of the service provider onboarding process. This would include details of your bank account.</li>
              <li>When you use the Application, you have to provide us with relevant information as may reasonably be required by us in order for the Application to work, for example
                <ol type="a">
                  <li>If you are using the Application as a user, you will need to provide us with information as to the type of service you seek, and address</li>
                  <li>If you are using the Application as a service provider, in order for the Application to work, you will need to provide us with the information on the services you are able to accept orders for at the time, and details as to your current, and after an order for a service placed by a user is accepted by you as the service provider, you may need to provide us with other data that we need to manage the Application and ecosystem, and to monitor overall usage of the Application.</li>
                  <li>If you are using the Application as a service provider, we will track and collect your geo-location information in real-time. In some cases, you will be prompted or required to activate the Global Positioning System (GPS) on your mobile device to enable us to give you a better experience in using the Application (for example, to give you information as to how close a service provider is to you). You can always choose to disable the geo-location tracking information on your mobile device temporarily. However, this can affect the functionalities available on the Application.</li>
                  <li>avcd</li>
                </ol>
              </li>
            </ul>

            <h3>2. PERSONAL INFORMATION WHICH WE COLLECT</h3>
            <p>We may use Personal Information collected for any of the following purposes as well as for such other purposes as are permitted by applicable law (“<strong>Purposes</strong>”):</p>
            <ol type="a">
              <li>Where you are a user, we may use your Personal Information</li>
                <ul>
                  <li>to identify you and to register you as a user and to administer, verify, deactivate, or manage your account as such;</li>
                  <li>to enable service providers to provide you with such of the services as you have requested;</li>
                  <li>to notify you of any transaction or activities occurred within the Application or other system linked to our Application;</li>
                  <li>to notify you of any updates to the Application or changes to the services available;</li>
                  <li>to process and respond to enquiries and feedback received from you;</li>
                  <li>to send you direct or targeted marketing communications, advertisement, vouchers, surveys, and information on special offers or promotions.</li>
                </ul>
              
              <li>Where you are a service provider, we may use your Personal Information:</li>
              <ul>
                <li>to identify you and to register you as a service provider and to administer, manage or verify your Account as such;</li>
                <li>to facilitate or enable any verification as we may in our discretion consider necessary before we register you as a service provider, including for KYC;</li>
                <li>to enable you to provide services to users;</li>

                <li>to communicate with you and send you information in relation to the provision of your services, including to relay user orders to you and to facilitate your acceptance of such orders;</li>
                <li>to provide you with notification and updates on the Application or changes to the manner in which services are to be provided;</li>
                <li>to provide you with a report in relation to the Services that you have provided;</li>
                <li>to process and respond to feedback from users on the services which you have provided;</li>
                <li>to maintain, develop, test, enhance and personalize the Application to meet your needs and preferences as a service provider;</li>
                <li>to offer or provide services from our affiliates or partners;</li>
                <li>to send you direct or targeted marketing communications, advertisement, promos, surveys, and information on special offers or promotions.</li>
              </ul>              
            </ol>

            <h3>3. SECURITY OF YOUR PERSONAL INFORMATION</h3>
            <p>Confidentiality of your Personal Information is of utmost importance to us. We will use all reasonable efforts to protect and secure your Personal Information against access, collection, use or disclosure by unauthorised persons and against unlawful processing, accidental loss, destruction and damage or similar risks. Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your Personal Information, you acknowledge that we cannot guarantee the integrity and accuracy of any Personal Information which you transmit over the Internet, nor guarantee that such Personal Information would not be intercepted, accessed, disclosed, altered or destroyed by unauthorised third parties, due to factors beyond our control. You are responsible for keeping your account details confidential and you must not share your account details, including your password and One Time Password (OTP), with anyone and you must always maintain and fully responsible for the security of the device that you use.</p>

            <h3>4. CHANGES TO THIS PRIVACY POLICY</h3>
            <p>We may review and amend this Privacy Policy at our sole discretion from time to time, to ensure that it is consistent with our future developments, and/or changes in legal or regulatory requirements. If we decide to amend this Privacy Policy, we will notify you of any such amendments by means of a general notice published on the Application and/or website. You agree that it is your responsibility to review this Privacy Policy regularly for the latest information on our data processing and data protection practices, and that your continued use of the Application or website, communications with us, or access to and use of the services following any amendments to this Privacy Policy will constitute your acceptance to this Privacy Policy and all of its amendments.</p>

            <h3>5. ACKNOWLEDGMENT AND CONSENT</h3>
            <ol type="a">
              <li>By accepting the Privacy Policy, you acknowledge that you have read and understood this Privacy Policy and you accept all of its terms. In particular, you agree and consent to us collecting, using, sharing, disclosing, storing, transferring, or otherwise processing your Personal Information in accordance with this Privacy Policy.</li>
              <li>In circumstances where you provide us with Personal Information relating to other individuals (such as Personal Information relating to your spouse, family members, friends, or other parties), you represent and warrant that you have obtained such individual’s consent for, and hereby consent on behalf of such individual to, the collection, use, disclosure and processing of his/her Personal Information by us.</li>
              <li>You may withdraw your consent to any or all collection, use or disclosure of your Personal Information at any time by giving us reasonable notice in writing using the contact details stated below. You may also withdraw your consent for us to send you certain communications and information via any “opt-out” or “unsubscribe” facility contained in our messages to you. Depending on the circumstances and the nature of the consent which you are withdrawing, you must understand and acknowledge that after such withdrawal of consent, you may no longer be able to use the Application or services. A withdrawal of consent by you may result in the termination of your account or of your contractual relationship with us, with all accrued rights and obligations remaining fully reserved. Upon receipt of your notice to withdraw consent for any collection, use or disclosure of your Personal Information, we will inform you of the likely consequences of such withdrawal so that you can decide if indeed you wish to withdraw consent.</li>
            </ol>

            <h3>6. MARKETING AND PROMOTIONAL MATERIAL</h3>
            <p>We and our partners may send you direct marketing, advertisement, and promotional communications via push-notification app, message in Application, post, telephone call, short message service (SMS), chat platform, social media, Chat Platform and Social Media, if you have agreed to subscribe to our mailing list, and/or consented to receive marketing and promotional materials from us. You may opt out from receiving such marketing communications at any time by clicking on any “unsubscribe” facility embedded in the relevant message, or otherwise contacting us using the contact details stated below. Please note that if you opt out, we may still send you non-promotional messages, such as ride receipts or information about your account.</p>

            <h3>7. HOW TO CONTACT US</h3>
            <p>If you have any questions regarding this Privacy Policy, you can contact us at the following:</p>
            <p><strong>Offices</strong></p>
            <ul>
              <li>Jalan Masjid Nomor 26 Purwokerto (main)</li>
              <li>Jalan Letkol Isdiman no. 114 b-c, Purbalingga</li>
            </ul>
            <p><strong>Call center</strong></p>
            <ul>
              <li>For Customer support: +62 8112885967</li>
            </ul>
           </div>
        </TermsAndConditions>
      </Container>
    )
    
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={renderMainContent} />
        <Route exact path="/faq" component={renderFaq} />
        <Route exact path="/terms-and-conditions" component={renderTnC}/>
        <Route exact path="/privacy-policy" component={renderPrivacyPolicy}/>
      </Switch>
    </Router>
  );
};

export default App;
