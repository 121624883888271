import Styled from "styled-components";

import { Colors } from "../../contants";

const FooterContainer = Styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.grey.light};
  overflow: hidden;
  padding: ${(props) =>
    props.width && props.width <= 960 ? "20px 32px" : "20px 80px"};

  .content {
    display: flex;
    flex-direction: ${(props) =>
      props.width && props.width <= 960 ? "column" : "row"};
    align-items: flex-start;
    justify-content: space-around;

    .title {
      margin-bottom: 10px;
      font-size: 1.2em;
    }
    
    .logo {
      margin-bottom: 5px;
    }
  
    .socmed {
      display: flex;
      justify-content: flex-end;
    }
  
    .add-margin-right {
      margin-right: 20px;
    }
  
    .social-media {
      height: 30px;
    }

    .sub-content {
      margin-bottom: 15px;
    }
  }

  .footer-end {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  p {
    font-size: 1em;
    font-family: "Maven Pro", sans-serif;
    margin: 0px;
    .year-text {
      font-weight: 700;
    }
  }
`;

export {
  FooterContainer
};