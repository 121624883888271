import serviceYoben from "./assets/images/service_yoben.jpg";
import appYoben from "./assets/images/yoben-phone-app.png";
import appYobenChoose from "./assets/images/yoben-phone-app-choose.png";
import appYobenRate from "./assets/images/yoben-phone-app-rate.png";
import appYobenReorder from "./assets/images/yoben-phone-app-reorder.png";

const serviceData = [
  {
    id: 1,
    title: "Paket Kost",
    desc: `Tinggal tunggu 60 menit, dan bersih sudah kost-an mu!
  Alat dan bahan pembersih kami sediakan, Sunggu hemat dan praktis untuk anak Kostan`,
    img: "/product-paket_kost.jpg",
    isPromo: false,
    service: "resik",
  },
  {
    id: 2,
    title: "Bersih Resik",
    desc: `Buat kamu yang tidak punya alat kebersihan
    Jasa kebersihan mulai dari 1 jam Hemat dan praktis!`,
    img: "/product-bersih_resik.jpg",
    isPromo: true,
    service: "resik",
  },
  {
    id: 3,
    title: "Resik Pribadi",
    desc: `Buat kamu yang lebih suka pakai alat sendiri
    Jasa kebersihan mulai dari 1 jam Praktis!`,
    img: "product-resik_pribadi.jpg",
    isPromo: false,
    service: "resik",
  },
  {
    id: 6,
    title: "Express Massage",
    desc: `Pijat cepat yang bisa nyelip di jadwal padatmu!
    Hanya 30 menit saja`,
    img: "product-express_massage.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 5,
    title: "Paket Body Massage",
    desc: `Pijat seluruh badan untuk menyegarkan kembali hari-harimu!
    Layanan ini memijat dari kepala hingga ujung kakimu,
    Siap memanjakan seluruh tubuh Anda.`,
    img: "product-body_massage.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 4,
    title: "Paket Setrika",
    desc: `Mulai dari tarif per 1 jam, siap melicinkan semua baju kantormu`,
    img: "product-paket_setrika.jpg",
    isPromo: false,
    service: "resik",
  },
  {
    id: 7,
    title: "Paket Body Massage + Totok Wajah",
    desc: `Tidak lupa untuk tetap tampil maksimal, dengan totok wajah dan pijat seluruh badan. Manjakan badan dan wajahmu, agar kembali bersinar!`,
    img: "product-body_massage_totok_wajah.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 8,
    title: "Paket Body Massage + Foot Reflexology",
    desc: `Cocok untuk kamu yang sering berdiri / pegal-pegal! Pijat seluruh badan disertai ekstra pijatan untuk bagian kakimu.`,
    img: "/product-body_massage_foot.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 9,
    title: "Paket Body Massage + Scrub",
    desc: `Paket Bersihkan Badan dan nikmati kelembutan kulit anda~ Rileks dan bersih, siap memanjakan diri anda!`,
    img: "product-body_massage_scrub.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 10,
    title: "Reflexology & Light Massage",
    desc: `Tanpa kelelahan pun, kamu bisa memanjakan dirimu!
    Pijat ringan dengan fokus pada pijatan kaki, siap merilekskan hari-harimu.`,
    img: "product-light_massage_reflexology.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 11,
    title: "Kerokan & Light Massage",
    desc: `Nikmati pijat ringan dengan sentuhan teknik pijat tradisional.`,
    img: "product-light_massage_kerokan.jpg",
    isPromo: false,
    service: "bugar",
  },
  {
    id: 12,
    title: "Body Massage & Kerokan",
    desc: `Pijat seluruh badan disertai dengan teknik pijat tradisional.`,
    img: "product-body_massage_kerokan.jpg",
    isPromo: false,
    service: "bugar",
  },
];

const locationData = [
  {
    id: 1,
    name: "Purwokerto, Jawa Tengah",
    desc: "Main Office",
  },
  {
    id: 2,
    name: "Purbalingga",
  },
];

let faqGeneral = [
  {
    id: 1,
    title: "Apakah itu YOBEN?",
    desc:
      "YOBEN merupakan perusahaan yang bergerak dibidang layanan panggilan. Untuk saat ini kami memiliki dua pelayanan yaitu BUGAR, menawarkan jasa pijat, dan RESIK menawarkan jasa kebersihan",
  },
  {
    id: 2,
    title: "Di mana saja layanan YOBEN tersedia?",
    desc:
      "Layanan YOBEN berada di Purwokerto dan Purbalingga.",
  },
  {
    id: 3,
    title: "Apakah layanan YOBEN bisa dipercaya?",
    desc:
      "Layanan YOBEN sangat terpercaya. Kami melatih mitra kami terlebih dahulu dengan standar yang profesional sebelum bergabung dengan YOBEN.",
  },
  {
    id: 4,
    title: "Apakah bisa order YOBEN lebih dari 1 mitra?",
    desc:
      "Anda bisa order layanan kami lebih dari 1 mitra.",
  },
  {
    id: 5,
    title: "Layanan YOBEN bisa dipesan sampai berapa hari di depan?",
    desc:
      "Layanan YOBEN bisa dipesan sampai tiga hari ke depan.",
  },
  {
    id: 6,
    title: "Apakah durasi layanan bisa diperpanjang apabila waktu pengerjaan kurang?",
    desc:
      "Anda bisa menambah jam layanan dengan konfirmasi ke mitra dan admin kami",
  },
  {
    id: 7,
    title: "Apakah list harga yang tertera sudah nett, atau ada biaya transport?",
    desc:
      "List harga kami sudah termasuk biaya transport.",
  },
  {
    id: 8,
    title: "Apakah saya bisa memesan kembali dengan mitra yang sudah pernah datang sebelumnya?",
    desc:
      "Ya, anda bisa meminta layanan dikerjakan dengan mitra favorit anda.",
  },
  {
    id: 9,
    title: "Bagaimana cara melakukan pembayaran?",
    desc:
      "Pembayaran dilakukan setelah layanan selesai dikerjakan. Pembayaran dapat dilaukan melalui uang tunai, BCA, GOPAY. dan OVO. Ketika sudah selesai melakukan pembayaran, mohon kiriman bukti transfer ke admin kami dinomor 62 811 2885 967",
  },
];

let faqBugar = [
  {
    id: 1,
    title: "Apakah YOBEN menyediakan tempat untuk pijat?",
    desc:
      "YOBEN ini adalah layanan panggilan ke tempat anda, kami tidak menyediakan tempat.",
  },
  {
    id: 2,
    title: "Untuk layanan massage apakah terapisnya pria dan wanita?",
    desc: "YOBEN mempunyai terapis pria dan wanita.",
  },
];

let faqResik = [
  {
    id: 1,
    title: "Untuk layanan RESIK, berapa mitra yang akan datang untuk mengerjakan?",
    desc:
      "Untuk layanan RESIK, yang akan datang kerumah anda satu mitra. Bila perlu lebih dari satu, bisa kabari saja ke admin dan harga akan dikalikan sesuai dengan jumlah mitra.",
  },
  {
    id: 2,
    title: "Apakah bisa meminta mitra untuk mengerjakan pekerjaan membersihkan taman atau pekarangan?",
    desc:
      "Ya bisa, akan tetapi mohon kabari admin terlebih dahulu agar kami bisa mencocokan mitra sesuai dengan kebutuhan anda.",
  },
];

const testimonies = [
  {
    id: 1,
    name: "Adit",
    place: "Purwokerto",
    age: "BUGAR",
    desc:
      "Terapis sopan, kebersihan sangat dijaga, kuat banget mijitnya, orang capek pasti sembuh lah. Saya masuk angin di pijit sama di korek Alhamdulillah ini udah enakan.",
    photo: serviceYoben,
  },
  {
    id: 2,
    name: "Dewi",
    place: "Purwokerto",
    age: "RESIK",
    desc:
      "Mantap kak, debu-debunya dibersihin semua dan rapi banget di tatain lagi barang-barangnya, puas banget kak. Terima kasih pelayanannya sangat membantu.",
    photo: serviceYoben,
  },
  {
    id: 3,
    name: "Lulu",
    place: "Purwokerto",
    age: "RESIK",
    desc:
      "Mbak nya nyetrikanya rapih banget, telaten. Bener-bener recommended dehh. Terima kasih YOBEN, meringankan beban kita. 🙏👍👍",
    photo: serviceYoben,
  },
  {
    id: 4,
    name: "Bayu",
    place: "Purwokerto",
    age: "BUGAR",
    desc:
      "Alhamdulillah, mantep banget kak pijatannya 🔥🔥. Terapisnya juga baik banget, makasih ya kak, sukses buat YOBEN!! ❤️",
    photo: serviceYoben,
  },
  {
    id: 5,
    name: "Gilang",
    place: "Purwokerto",
    age: "RESIK",
    desc:
      "Makasi ya, seneng dateng-dateng dah bersih kamar nya. Pokoknya mentep pol deh. Kamar mandi berish juga. Terus minta bersihin sarang laba-laba di langit-langit juga bersih semua.",
    photo: serviceYoben,
  },
  {
    id: 6,
    name: "Fajri",
    place: "Purwokerto",
    age: "RESIK",
    desc:
      "Terima kasih YOBEN atas jasanya, kamar jadi kinclong lagi dan wangi membantu banget buat yang lagi sibuk kerjaan atau ga sempat beberes kamar, jadi semakin terbantu dan hasilnya pun memuaskan. 🙏🙏",
    photo: serviceYoben,
  },
];

const steps = [
  {
    id: 0,
    title: "Download Aplikasi YOBEN",
    img: "yoben_mobile_app.png",
  },
  {
    id: 1,
    title: "Pilih layanan",
    desc: "Kami menyediakan layanan kebersihan dan pijat profesional!",
    img: "manual_step_2.png",
  },
  {
    id: 2,
    title: "Mitra kami hadir di tempat anda",
    desc: "Favoritkan mitra untuk order dengan mitra yang sama kembali!",
    img: "manual_step_3.png",
  },
];

export {
  serviceData,
  locationData,
  faqGeneral,
  faqBugar,
  faqResik,
  testimonies,
  steps,
};
