import React from "react";

import { useViewport, History as history } from "../../../helpers";
import { CustomText, CustomButton } from "../../../components";
import { AboutUs } from "./style";
import { Colors } from "../../../contants";

const AboutUsComponent = () => {
  const { width } = useViewport();
  return (
    <AboutUs id="aboutUs" width={width}>
      <div className="wrapper">
        <div className="content">
          <div className="container left" id="about-left">
            <div className="service-yoben-image">
              <img src={"about-us.jpg"} alt="service YOBEN" />
            </div>
          </div>
          <div className="container right" id="about-right">
            <CustomText
              color={Colors.grey.default}
              size="medium"
              margin="0 0 10px 0"
              weight="700"
              text="TENTANG KAMI"
            />
            <CustomText
              color={Colors.black.default}
              size={width <= 960 ? "large-med" : "large"}
              margin="0 0 20px 0"
              weight="700"
              text="Layanan terbaik, Hanya untuk Anda"
            />
            <CustomText
              color={Colors.grey.default}
              size="medium"
              margin="0 0 20px 0"
              weight="500"
              text="Mitra kami terlatih untuk memberikan layanan terbaik untuk Anda.
              Mengutamakan kepuasan pelanggan dan professionalitas."
            />
            <div className="insight-yoben-container">
              <div className="insight-yoben-subcontainer">
                <div>
                  <CustomText
                    color={Colors.blue.default}
                    size="large-med"
                    margin="0 0 5px 0"
                    weight="700"
                    text={"3000+"}
                  />
                  <CustomText
                    color={Colors.grey.default}
                    size="medium"
                    margin="0 0 30px 0"
                    weight="500"
                    text="TOTAL PESANAN"
                  />
                </div>
                <div>
                  <CustomText
                    color={Colors.orange.default}
                    size="large-med"
                    margin="0 0 5px 0"
                    weight="700"
                    text="20"
                  />
                  <CustomText
                    color={Colors.grey.default}
                    size="medium"
                    margin="0 0 0 0"
                    weight="500"
                    text="MITRA RESIK"
                  />
                </div>
              </div>
              <div className="insight-yoben-subcontainer">
                <div>
                  <CustomText
                    color={Colors.orange.default}
                    size="large-med"
                    margin="0 0 5px 0"
                    weight="700"
                    text="600+"
                  />
                  <CustomText
                    color={Colors.grey.default}
                    size="medium"
                    margin="0 0 30px 0"
                    weight="500"
                    text="PELANGGAN"
                  />
                </div>
                <div>
                  <CustomText
                    color={Colors.blue.default}
                    size="large-med"
                    margin="0 0 5px 0"
                    weight="700"
                    text="12"
                  />
                  <CustomText
                    color={Colors.grey.default}
                    size="medium"
                    margin="0 0 0 0"
                    weight="500"
                    text="MITRA BUGAR"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutUs>
  );
};

export default AboutUsComponent;
