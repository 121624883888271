import Styled from "styled-components";
import { Layout } from "antd";

import { Colors } from "./contants";

const Container = Styled.div`
  font-family: "Maven Pro", sans-serif;
  align-items: center;
  width: 100%;
  scroll-behavior: smooth;
  transition: transform 500ms;
  height:${(props) => (props.width <= 960 ? "100vh" : "100%")};
  width: 100%;
  .main-content {
    margin-top: 80px;
  }

  .burger {
    position: ${(props) => (props.width <= 960 ? "fixed" : "sticky")};
    margin-left: 32px;
    top: 32px;
    z-index: 101;
    height: 0px;
  }
`;

const Navigation = Styled.div`
  width: 100%;

  .nav-container {
    align-items: center;
    background-color: white;
    display: flex;
    justify-content: space-between;
    height: 80px;
    position: -webkit-sticky;
    position: fixed;  
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    ${(props) =>
      props.width && props.width >= 2000
        ? "padding: 0px 25vw;"
        : props.width && props.width >= 1250
        ? "padding: 0px 12vw;"
        : "padding: 0px 80px;"}

  }
  .nav-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .text-primary {
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    /* background: yellow; */
    width: 60%;
    
    p {
      cursor: pointer;
      color: ${(props) =>
        props.scrollX > 0 ? Colors.black.default : Colors.black.default};
      margin-top: revert;
      
      &:hover {
        opacity: unset;
        box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
        transform: translateY(-4px);
      }
    }

    .active {
      color: ${(props) =>
        props.scrollX > 0 ? Colors.blue.default : Colors.blue.default};
    }
    
  }

  .nav-instagram {
    display: flex;
    border: 1px solid #55bbcc;
    padding: 0px 15px;
    border-radius: 8px;
    height: 40px;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: unset;
      box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
      transform: translateY(-4px);
    }
    p {
      margin-top: revert;
    }
  }

  .follow {
    color: #55bbcc;
    font-weight: 700;
    margin-left: 10px;
  }

`;

const NavigationMobile = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  position: -webkit-sticky;
  position: fixed;  
  top: 0;
  left: 0;
  background: ${Colors.white.default};
  z-index: 100;
  height: 80px;
  min-height: 80px;
  width: 100%;
  .nav-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const Text = Styled.p`
  font-family: "Maven Pro", sans-serif;
  color: ${(props) => (props.color ? props.color : Colors.black.default)};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => (props.size === "large" ? "3em" : "")};
  margin: ${(props) => props.margin};
`;

const OurService = Styled.section`
  overflow: hidden;
  .wrapper {
    background: #f7fcfd;
    padding: ${(props) =>
      props.width && props.width >= 2000
        ? "60px 25vw"
        : props.width && props.width >= 1250
        ? "60px 12vw"
        : props.width && props.width <= 960
        ? "60px 32px"
        : "60px 80px"};

    .content {
      margin: auto;
      flex-direction: column;
      display: flex;

      .content-description {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        ${(props) =>
          props.width && props.width <= 960
            ? "flex-direction: column"
            : "flex-direction: row"};
        ${(props) =>
          props.width && props.width <= 960 && "align-items: flex-start"};
        ${(props) => props.width && props.width <= 960 && "margin-right: 32px"};

        .content-left {
          display:flex;
          flex-direction: column;
  
          h2 {
            line-height: 23px;
            letter-spacing: 2px;
            color: #646C71;
          }
    
          h1 {
            font-size: 3em;
            margin: 0;
            ${(props) =>
              props.width &&
              props.width <= 960 &&
              "font-size: 2em;margin-bottom: 30px"};
            font-weight: 700;


          }
    
          .resik-text {
            color: #2D688C;
            font-weight: 700;
          }
    
          .bugar-text {
            color: ${Colors.blue.default};
            font-weight: 700;

          }
        }
  
        .content-right {
          display:flex;
          overflow: scroll;
          ${(props) => props.width && props.width <= 960 && "width: 100%"};
          min-height: 45px;
          align-items: flex-end;
          margin-right:  ${(props) =>
            props.width && props.width > 960 ? "80px" : "0px"};
          ::-webkit-scrollbar {
            display: none;
          }

          .filter-content {
            background: ${(props) => {
              if (props.selected) {
                return "yellow";
              } else {
                return "green";
              }
            }};
          }
        }
      }
    }
  }`;

const FilterServiceButton = Styled.button`
  border: none;
  border-radius: 100px;
  background: ${(props) => (props.selected ? "#55BBCC" : "transparent")};
  min-width: fit-content;
  cursor: pointer;
  height: 40px;

  :focus {
    outline: none
  }

  p {
    padding: 0px 20px;
    color: ${(props) => {
      return props.selected ? "#FFFFFF" : "#2D688C";
    }};
    opacity: ${(props) => (props.selected ? "" : "0.3")};
  }

  p:hover {
    color: ${(props) => (props.selected ? "#FFFFFF" : "#55BBCC")};
    opacity: 1;
  }
`;

const ServiceList = Styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding-bottom: 20px;
`;

const ServiceCard = Styled.div`
  min-width: ${(props) =>
    props.width && props.width <= 960 ? "250px" : "350px"};
  max-width: 350px;
  background: white;
  margin-right: 30px;
  margin-top: 40px;
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(45, 104, 140, 0.08);
  min-height: 362px; 

  &:hover {
    opacity: unset;
    box-shadow: rgba(44, 112, 174, 0.5) 0px 15px 20px -15px;
    transform: translateY(-4px);
  }

  img {
    margin: auto;
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
  }

`;

const HowItWorks = Styled.section`
.wrapper {
  background: #FFFFFF;
  padding: ${(props) =>
    props.width && props.width >= 2000
      ? "60px 25vw"
      : props.width && props.width >= 1250
      ? "60px 12vw"
      : props.width && props.width <= 960
      ? "60px 32px"
      : "60px 80px"};

  .content {
    margin: auto;
    flex-direction: ${(props) =>
      props.width && props.width <= 960 ? "column" : "row"};
    ${(props) => props.width && props.width <= 960 && "align-items: center"};
    display: flex;

    .content-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      position: relative;
      ${(props) => props.width && props.width > 960 && "margin-right: 40px"};

      img {
        margin: ${(props) =>
          props.width && props.width <= 960 ? "0 0 40px 0" : " 0 0 0 80px"};
        display: ${(props) =>
          props.width && props.width <= 960 ? "none" : " block"};
        // width: 350px;
        width: 75%;
        object-fit: cover;
        // border-radius: 10px;
        z-index: 3;
      }


      .decor-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 160px;

      }
      .decor-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 160px;

      }
    }
  
    .content-right {
      display: flex;
      width: ${(props) => (props.width && props.width <= 960 ? "100%" : "50%")};
      flex-direction: column;
      ${(props) => props.width && props.width > 960 && "width: 40%"};

      h1 {
        font-family: Maven Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 52px;
        color: #313537;
      }
  
      h2 {
        line-height: 23px;
        letter-spacing: 2px;
        color: #646C71;
      }

      .content-description {
        margin: 20px 0px;
      }

      .step-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .step-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            display: flex;
            align-items: center;
          }

          .step-number {
            background: ${Colors.blue.default};
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            width: 32px;
            height: 32px;

            p {
              font-family: Maven Pro;
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 23px;
              text-align: center;
              color: #FFFFFF;
              margin-left: 0px;
              margin-top: revert;
              width: 32px;
            }
          }
        }
  
        .step-detail {
          ${(props) =>
            props.width && props.width <= 960 && "padding: 0px 32px"};
          ${(props) =>
            props.width && props.width <= 960 && " margin-top: 20px;"};
          margin-left: ${(props) =>
            props.width && props.width <= 960 ? "0px" : "50px"};
          ${(props) =>
            props.width &&
            props.width <= 960 &&
            "display: flex; align-items: center; flex-direction: column"};
          .app-img {
            display: block;
            width: ${(props) =>
              props.width && props.width <= 300
                ? "200px"
                : props.width && props.width <= 430
                ? "250px"
                : "350px"};
            object-fit: cover;
            // border-radius: 10px;
          }

          ${(props) =>
            props.width &&
            props.width <= 960 &&
            "p { text-align: center; margin-top: 20px;}"};

        }
      }
    }
  }
}`;

const HowItWorksTitle = Styled.p`
  font-family: Maven Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #313537;
  opacity: ${(props) => props.isSelected ? 1 : 0.5};
  margin-top: revert;
  width: ${(props) => props.width <= 960 ? 100% - 40 : "100%"};
`

const stepInfo = Styled.div`
  background: red
`;

const Testimonial = Styled.section`
overflow: hidden;

.wrapper {
  // background: #FFFFFF;
  padding: 60px 0;

  .content {
    margin: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .content-title {
      display: flex;
      flex-direction: column;
      align-items:  ${(props) =>
        props.width && props.width <= 960 ? "start" : "center"};
      // margin-bottom: 40px;
      padding: ${(props) =>
        props.width && props.width <= 960 ? "0px 30px" : "0px 80px"};

      h1 {
        font-family: Maven Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 52px;
        color: #313537;
      }

      h2 {
        line-height: 23px;
        letter-spacing: 2px;
        color: #646C71;
      }
    }

    .carousel-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${(props) =>
        props.width && props.width <= 350
          ? "0px 10px"
          : props.width && props.width <= 1024
          ? "0px 30px"
          : props.width && props.width >= 2000
          ? "0px 25vw"
          : props.width && props.width >= 1250
          ? "0px 20vw"
          : "0px 20vw"};

      .arrow{
        img {
          width: 20px
        }
      }
    }

    .BrainhubCarousel__dots {
      margin-top: 20px;
    }

    .BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
      background: transparent;

    }
    .BrainhubCarousel__dots .BrainhubCarousel__dot {
      background: transparent;

    }


    .testi-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 450px;

    }

    .carousel .slide {
      background: transparent;
    }
  
    .react-multi-carousel-track {
      padding: 0px 80px;
    }
    .carousel.carousel-slider {
      padding-bottom: 50px;
      width: 100vw !important;
    }
  
    .carousel .control-dots {
      z-index: 1;
      bottom: 0;
      margin-bottom: 0px;
  
      .dot {
        margin: 0 4px;
        height: 10px;
        width: 10px;
        box-shadow: none;
        opacity: 1;
        background-color: ${Colors.grey.default};
        transition: all 0.3 ease;
      }
  
      .selected {
        background-color: ${Colors.blue.default};
      }
    }
  }
}
`;

const CardTestimony = Styled.div`
  width: ${(props) =>
    props.width && props.width <= 1024 && props.width > 780
      ? "40vw"
      : props.width && props.width <= 780
      ? "70vw"
      : "20vw"};
  background-color: ${Colors.white.default};
  border-radius: 30px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px rgba(45, 104, 140, 0.1);
  margin: 20px 0px;
  &:hover {
    opacity: unset;
    box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
    transform: translateY(-4px);
  }
  p {
    text-align: start;
  }

  .ic-quote {
    width: ${(props) =>
      props.width && props.width <= 780
        ? "40px !important"
        : "60px !important"};
  }

  .user-profile {
    display: flex;
    flex-direction: row;
  }


  .ant-avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin-right: 20px;
  }
`;

const Menu = Styled.div`
  display: flex;
  z-index: 100;
  position: -webkit-sticky;
  position: fixed;
  top: 0px;
  height: 100%;
  
  .overlay {
    background: rgba(0,0,0,0.2);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  .link {
    margin-left: -100vw;
    position: absolute;
    top: 0px;
    left: 0;
    width: 75vw;
    height: 100vh;
    background: ${Colors.white.default};
    padding: 135px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Maven Pro", sans-serif;

    .top, .bottom {
      display: flex;
      flex-direction: column;
    }

    .top {
      height: auto;
      // grid-gap: 40px;
      display: initial;
      
      p {
        font-family: "Maven Pro", sans-serif;
        font-size: 1em;
        color: ${Colors.black.default};
        text-align: left;
        margin-bottom: 40px !important;

        &:last-child {
          margin-bottom: 0px !important;

        }
      }
    }

    .bottom {
      height: auto;
      // grid-gap: 20px;

      .nav-instagram {
        display: flex;
        border: 1px solid #55bbcc;
        padding: 0px 15px;
        border-radius: 8px;
        height: 40px;
        align-items: center;
        cursor: pointer;
    
        .follow {
          color: #55bbcc;
          font-weight: 700;
          margin-left: 10px;
        }

        &:hover {
          opacity: unset;
          box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
          transform: translateY(-4px);
        }
        p {
          margin-top: revert;
        }
      }
    
    }
  }
  
`;

const WhatAreYouWaiting = Styled.div`
  padding: ${(props) =>
    props.width && props.width >= 2000
      ? "60px 25vw"
      : props.width && props.width >= 1250
      ? "60px 12vw"
      : props.width && props.width <= 960
      ? "60px 32px"
      : "60px 80px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.blue.default};
  position: relative;

  .ant-btn {
    transition: all 0.3s ease;

    &:hover {
      opacity: unset;
      box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
      transform: translateY(-4px);
    }

  }

  .effect {
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    width: 135px;
    height: 135px;
  }

  .card-bottom {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .ellipse-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .ellipse-top {
    position: absolute;
    top: 0;
    right: 0;
  }

  ${(props) => props.width && props.width <= 960 && "p{text-align:center;}"}

`;

const DownloadApp = Styled.div`
  padding-top: 10px;
  display: flex;
  .ant-btn {
    min-width: ${(props) =>
      props.width && props.width <= 430
        ? "20px"
        : "180px"};
    height: 60px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    img {
      width: 32px;
    }
  }
`;

const AvailableAt = Styled.div`
  background-color: ${Colors.blue.light};
  padding: ${(props) =>
    props.width && props.width >= 2000
      ? "60px 25vw"
      : props.width && props.width >= 1250
      ? "60px 12vw"
      : props.width && props.width <= 960
      ? "60px 32px"
      : "60px 80px"};
  display:  ${(props) =>
    props.width && props.width <= 960 ? "block" : "flex"};
  .detail-container {
    width: ${(props) => (props.width && props.width <= 960 ? "100%" : "50%")};
    margin-right: 60px;
  }
  .map-container {
    width: ${(props) => (props.width && props.width <= 960 ? "100%" : "40%")};
    ${(props) => props.width && props.width <= 960 && "margin-top: 20px;"};
    img {
      ${(props) =>
        props.width && props.width <= 960
          ? "width: 300px"
          : props.width >= 1500
          ? "width: 500px"
          : "width: 40vw"};
    }
  }
`;

const LocListItem = Styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    height: 20px;
    margin-right: 20px;
    
  }

  p {
    font-weight: 700;
    font-size: 1em;
    font-family: "Maven Pro", sans-serif;
    margin: 0px;
  }

  .loc-desc {
    color: ${Colors.blue.default}
  }
`;

const FAQ = Styled.div`
  max-width: 100vw;
  ${(props) =>
   props.width && props.width >= 2000
     ? "padding: 0px 25vw"
     : props.width && props.width > 1500
     ? "padding:0px 12vw"
     : "padding:0px 0px"};

  .content {
    margin-top: 80px;
  }
  .faq-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    img {
      max-width: 100vw;
      height: ${(props) =>
        props.width <= 320 ? "30vh" : props.width <= 960 ? "55vh" : "350px"};
      object-fit: cover;
      width: 100%;
    }

  }
  .faq-title {
    position: absolute;
    // top: 0;
    // margin-top: ${(props) => (props.width <= 960 ? "15vh" : "80px")};
    // padding: ${(props) => (props.width <= 960 ? "0px 32px" : "0px 80px")};
    text-align: center;
    .contact-button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .keep-in-touch-input {
      padding:20px;
      border-radius: 10px;
      width: 100%;
      margin-right: 20px;
      background: #ffffff;
      border: none;
      font-family: "Maven Pro", sans-serif;
  
      :focus {
        outline: none
      }
    
    }
  }
`;

const QuestionsContainer = Styled.div`
  padding: ${(props) =>
    props.width && props.width >= 2000
      ? "60px 10vw"
      : props.width && props.width >= 1250
      ? "60px 12vw"
      : props.width && props.width <= 960
      ? "20px"
      : "60px 260px"};

  .filter-container {
    display: flex;
    ${(props) => props.width && props.width > 960 && "justify-content: center"};
    overflow-y: scroll;
    width: ${(props) => (props.width && props.width <= 960 ? "80vw" : "100%")};
    height: 40px;
    align-items: center;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    background: transparent;
    border-radius: 18px;
    margin-bottom: 24px;
    border: none;
    overflow: hidden;
  }

  .ant-collapse {
    margin-top: 40px;
    border: none;
    background: transparent;

  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    border-radius: 16px;
    border: none;
    background: #F2F2F2;
    font-size: 1.25em;
    font-weight: 700;
    font-family: "Maven Pro", sans-serif;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item-active > .ant-collapse-header {
    background: transparent;
    color: ${Colors.blue.default}
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
    margin-bottom: 10px;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 20px;
  }
`;

const TermsAndConditions = Styled.div`
  padding: ${(props) =>
    props.width && props.width >= 2000
      ? "60px 10vw"
      : props.width && props.width >= 1250
      ? "60px 12vw"
      : props.width && props.width <= 960
      ? "20px"
      : "60px 260px"};

  .content {
    margin-top: 80px;
    word-break: break-word;
  }

  .tnc-title {
    text-align: center;
  }
`

export {
  Container,
  Navigation,
  NavigationMobile,
  Text,
  OurService,
  FilterServiceButton,
  ServiceCard,
  ServiceList,
  HowItWorks,
  HowItWorksTitle,
  stepInfo,
  Testimonial,
  Menu,
  WhatAreYouWaiting,
  DownloadApp,
  CardTestimony,
  AvailableAt,
  LocListItem,
  FAQ,
  QuestionsContainer,
  TermsAndConditions
};
