import styled from "styled-components";

const Container = styled.div`
  position: relative;
  .max-layout {
    ${(props) =>
      props.width && props.width >= 2000
        ? "margin: 0px 25vw;"
        : props.width && props.width >= 1250
        ? "margin: 0px 10vw;"
        : props.width <= 600
        ? "margin: 0px 32px"
        : "margin: 0px 80px;"};
  }

  .contact-us-left {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    ${
      (props) =>
        props.width && props.width >= 2000
          ? "padding: 80px 0px;"
          : props.width && props.width >= 1250
          ? "padding: 100px 0px;"
          : props.width <= 600
      //       ? "padding: 100px 0px"
      //       : "padding: 100px 0px;"
    };
  }

  .knowmore-text {
    font-size: 1em;
    font-weight: 700;
    font-family: "Maven Pro", sans-serif;
    display: flex;
    cursor: pointer;
    &:hover {
      opacity: unset;
      box-shadow: rgba(44, 112, 174, 0.5) 0px 22px 30px -15px;
      transform: translateY(-4px);
    }
    img {
      margin-right: 10px;
    }
  }

  .contact-us-container {
    background: #55bbcc;
    padding: 60px 0;
  }

  .decor-ellipsis {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .decor-ellipsis-small {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .decor-line-short {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .decor-line-long {
    position: absolute;
    left: 0;
    top: 447px;
    z-index: 0;
    width: 100vw;
  }

  .keep-in-touch-input {
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    margin-right: 20px;
    background: #ffffff;
    border: none;
    font-family: "Maven Pro", sans-serif;

    :focus {
      outline: none;
    }
  }
  .right-image-container {
    display: flex;
    align-items: center;
  }

  .contact-us-right {
    display: flex;
    max-width: 50%;
    align-items: center;
    margin-left: 40px;

    img {
      width: 100%;
      height: 100%;
      border: 8px solid white;
      border-radius: 20px;
      object-fit: cover;
    }

    .contact-us-image {
      height: 80%;
      min-height: 300px;
    }
  }
`;

export { Container };
