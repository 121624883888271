import React from 'react';

import { Text } from './style';

const CustomText = props => {
  return (
    <Text
      className={ props.className ? props.className : '' }
      color={ props.color ? props.color : undefined }
      weight={ props.weight ? props.weight : undefined }
      margin={ props.margin ? props.margin : undefined }
      size={ props.size ? props.size : undefined }
      lineHeight={ props.lineHeight ? props.lineHeight : undefined }
    >
      {props.text ? props.text : ''}
    </Text>
  );
};

export default CustomText;